<script>


import {
    required,
    helpers
  } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { mapActions } from "vuex";

//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
/*
import formAnagraficaCliente from "@/components/widgets/formAnagraficaCliente";
import projectManagerPV from "@/components/widgets/projectManagerPV";

import documentManager from "@/components/widgets/documentManager";
import surveyManager from "@/components/widgets/surveyManager";
import accountingManager from "@/components/widgets/accountingManager";
*/

//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import pianificazioneLavori from "@/components/widgets/worksites/pianificazioneLavori";

import gestioneTeamDiLavoro from "@/components/widgets/worksites/gestioneTeamDiLavoro";
import gestioneMezziCantiere from "@/components/widgets/worksites/gestioneMezziCantiere";
import gestioneAttrezzaturaCantiere from "@/components/widgets/worksites/gestioneAttrezzaturaCantiere";
import elencoProdottiCantiere from "@/components/widgets/worksites/elencoProdottiCantiere";


import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';

  
  export default {
    name:'detailMainWorksite',
    props: [
      'projectID',
    ],
    setup() {     
      let tk = 'Bearer '+localStorage.getItem('tk')

      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Lista cantieri",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        data:{},
        worksite:{
            projectID: this.projectID,
            area:"",
            status:"init", // 'init'/'inprogress'/'pending'/'completed'/'problem'
            name:"",
            description:"",
            type:"",
            country:"",
            zipCode:"",
            city:"",
            address:"",
            address_number:"",
            additional_info_to_address:"",
            cordinate_system:"",
            longitude:"",
            latitude:"",
            year: new Date().getFullYear(),
            dateString:"",
            dateTmp:0,
        
            tasks:[],
            createdBy:this.$store.state.auth.currentUser,
            versions_history:{},
            assignedTo:[],
            startStr: "", 
            endStr: "",
            start: moment.unix(),
            end: "",
            start_tmp: moment.unix(),
            end_tmp: "",
            color: "",
            icon: "",
            url: "",
            geolocal:"",
            
            statusClass: "info",
            priority: "low",
            priorityClass: "success",
        },
        activeTab:'Localizzazione cantiere',
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:moment().format('YYYY-MM-DD'),
        tabProdotti:false,
        tabTimeline:false,
        tabTeam:false,
        tabMezzi:false,
        tabAttrezzature:false,
      };
    },
    components: {
      pianificazioneLavori,
      elencoProdottiCantiere,
      gestioneTeamDiLavoro,
      gestioneMezziCantiere,
      gestioneAttrezzaturaCantiere,
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    mounted() {
    this.getItem()
        
    },
    computed:{
     
    },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    setTitle(par){
      this.activeTab = par
    },
    getParent(){
      this.getItem()
    },
    setEndDate(){
      /*
      if (this.data.worksite.startStr){
        this.minDate = this.data.worksite.startStr
        this.maxDate = this.data.worksite.startStr
      }
      alert( moment().format('YYYY-MM-DD').add(30, 'days'))
      */
     this.update()

    },
    getItem() {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}worksites/by-projectid/${this.projectID}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data = response.data   
            if (!this.data.worksite.startStr){
              this.data.worksite.startStr = moment().format('YYYY-MM-DD')
            }  
            if (!this.data.worksite.endStr){
              this.data.worksite.endStr = moment().format('YYYY-MM-DD')
            }          
          }        
      })
    },
    init(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}worksites/register`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          this.getItem()
          Swal.fire( this.$t("t-msg-worksite-tab-created-title") , this.$t("t-msg-worksite-tab-created-content"), "success");
        }
      })
    },
    update(){
     
     this.axiosInterceptor()
     
     this.data.worksite.dateString = this.data.worksite.startStr
     this.data.worksite.dateTmp = moment(this.data.worksite.startStr).unix()
     this.data.worksite.year = moment().format('YYYY')
     this.data.worksite.month = this.data.worksite.dateString.split('-')[1]
     this.data.worksite.day =  this.data.worksite.dateString.split('-')[2]
     this.data.worksite.start = moment(this.data.worksite.startStr).valueOf()
     this.data.worksite.start_tmp = moment(this.data.worksite.startStr).unix()
    
     this.data.worksite.end = moment(this.data.worksite.endStr).valueOf()
     this.data.worksite.end_tmp = moment(this.data.worksite.endStr).unix()

     axios.put(`${this.UrlServer}worksites/${this.data.worksite._id}`, this.data.worksite, {headers: { authorization:this.tk}})
     .then(() => {
         //this.getItem()
         //Swal.fire( this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
     }).catch(() => {  
       Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
     });
   }, 
  },
  beforeMount() {

  },
  };
</script>

<template>
 <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <div class="card-title mb-0 flex-grow-1">
                <div class="row">

                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                      <div class="input-group-prepend">

                        <span class="pr-5" v-if="$route.name=='worksite-detail'"><strong>Gestione lavori</strong></span> 
                        <span class="pr-5" v-if="$route.name=='support-detail'"><strong>Gestione attività di assistenza</strong></span>
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
               v-if="data"
              >
              
                <template v-if="data.project">
                  <select class="form-select"  v-model="data.worksite.status"  @change="update()"  >
                    <option value="">Stato cantiere</option>
                       <option value="init">Avviato</option>
                       <option value="in progress">Lavorazione in corso</option>
                       <option value="blocked">Lavorazione Bloccata</option>
                       <option value="canceled">Lavorazione Annullato</option>
                       <option value="completed">Lavorazione Completata</option>
                       <option value="installed">Impianto installato</option>
                  </select>
                </template>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="data">
            
              <template v-if="data.worksite == false && data.project != false">
                <label class="alert alert-info"><strong>Attenzione:</strong> {{data.message}}</label>
                <div class="mb-3">

                  <button type="button" class="btn btn-primary" @click="init()"><i class="bx bx-settings"></i>Avvia lavorazioni >></button>
                  </div>
              </template>
              <template v-else-if="data.worksite == null " > 
                <label class="alert alert-info"><strong>Attenzione:</strong> {{data.message}}</label>
                <div class="mb-3">

                  <button type="button" class="btn btn-primary" @click="init()"><i class="bx bx-settings"></i>Avvia lavorazioni >></button>
                  </div>
              </template>
              <template v-else-if="data.worksite == false && data.project == false">
                <label class="alert alert-danger">{{data.message}}</label>
                <br>
                
              </template>
              <template v-else>
                <!-- Anagrafica cliente -->
                <div class="row" v-if="data.project">
                  <div class="col-lg-12">
                    <b-accordion class="custom-accordionwithicon-plus" id="accordionWithplusicon">
                    <b-accordion-item :title="data.project.customerDetails.ragSoc" unvisible>
                      <div class="table-responsive">
                         <table class="table mb-0">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Nominativo</th>
                                                      <th scope="col">Indirizzo</th>
                                                      <th scope="col">Città</th>
                                                      <th scope="col">Telefono</th>
                                                      <th scope="col">Email</th>
                                                      <th scope="col">#</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody v-if="data.project">
                                                    <tr>
                                                      <td>{{ data.project.customerDetails.ragSoc }}</td>
                                                      <td>{{ data.project.customerDetails.indirizzo }}</td>
                                                      <td>{{ data.project.customerDetails.citta }}</td>
                                                      <td>{{ data.project.customerDetails.telefono }}</td>
                                                      <td><a :href="'mailto:'+data.project.customerDetails.email">{{ data.project.customerDetails.email }}</a></td>
                                                      <td><button class="btn btn-info" title="Vedi anagrafica completa">Vedi</button></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                    </b-accordion-item>
                    <b-accordion-item :title="'Localizzazione Cantiere: '+data.worksite.address +' - '+data.worksite.city" unvisible>
                      <div class="row" v-if="data.worksite">
                        <div class="col-lg-12">
                          <div class="card-body mb-50">
                            <div class="row mb-50 pl-10">
                                <div class="col-md-12 col-lg-4">
                                  <label for="code-field" class="form-label"><strong>Indirizzo</strong></label>
                                    <div class="input-group mb-1">
                                        <input type="text" v-model="data.worksite.address" class="form-control" placeholder="Inserisci Indirizzo" @blur="update()" />
                                        <div class="input-group-append">
                                          <span class="input-group-text " ><i class="bx bxs-home mb-10"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-3">
                                  <label for="code-field" class="form-label"><strong>Città</strong></label>
                                  <div class="input-group mb-1">
                                    <input type="text" v-model="data.worksite.city" class="form-control" placeholder="Inserisci Città"  @blur="update()"   />
                                    <div class="input-group-append">
                                      <span class="input-group-text" ><i class="bx bxs-city mb-10"></i></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 col-lg-2">
                                  <label for="code-field" class="form-label"><strong>C.A.P</strong></label>
                                  <div class="input-group mb-1">
                                    <input type="text" v-model="data.worksite.zipCode" class="form-control" placeholder="Cap"  @blur="update()"  />
                                    <div class="input-group-append">
                                      <span class="input-group-text " ><i class="bx bx-current-location mb-10"></i></span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="row mb-50 mt-20 pl-10">
                              <div class="col-md-12 col-lg-2">
                                <label for="code-field" class="form-label"><strong>Inizio lavori</strong></label>
                                <div class="input-group mb-1">
                                  <input type="date" v-model="data.worksite.startStr" :min="minDate" class="form-control" placeholder="Inserisci data inizio"  @blur="update()"  />
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-2">
                                <label for="code-field" class="form-label"><strong>Fine lavori</strong></label>
                                  <div class="input-group mb-1">
                                    <input type="date" v-model="data.worksite.endStr" :min="minDate" :max="maxDate" class="form-control" placeholder="Inserisci data fine"  @blur="setEndDate()"   />
                                </div>
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </b-accordion-item>
                  </b-accordion>
                  </div>
                </div>
          <!--
          <div class="row mt-5">
            <div class="col-lg-12">            
              <div class="card-body pl-10 mb-20">          
                    <button class="btn btn-info" type="button" @click="$router.push('/fgas/detail/'+this.$route.params.id)">
                      <i class="bx bx-calendar-edit"></i>
                        Gestione FGAS
                    </button>  
                  </div>
              </div>
          </div>
          -->
                
                  <div class="card-body mt-30">
                    <p class="text-muted">Schede di lavorazione</p>
                    <ul class="nav nav-pills nav-customs nav-danger mb-3" role="tablist">

                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" data-bs-toggle="tab" href="#nav-pianifica-lavori" role="tab" @click="tabSelect('nav-pianifica-lavori')" aria-selected="true">Lavorazioni giornaliere</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" data-bs-toggle="tab" href="#nav-componenti" @click="tabProdotti=true" role="tab" aria-selected="false" tabindex="-1">Componenti utilizzati</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" data-bs-toggle="tab" href="#nav-timeline" @click="tabTimeline=true" role="tab" aria-selected="false" tabindex="-1">Timeline (progresso lavori)</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" data-bs-toggle="tab" href="#nav-team" @click="tabTeam=true" role="tab" aria-selected="false" tabindex="-1">Team impiegati</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" data-bs-toggle="tab" href="#nav-mezzi" @click="tabMezzi=true" role="tab" aria-selected="false" tabindex="-1">Mezzi utilizzati</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" data-bs-toggle="tab" href="#nav-attrezzature" @click="tabAttrezzature=true" role="tab" aria-selected="false" tabindex="-1">Attrezzature utilizzate</a>
                      </li>
                    </ul>
                    <div class="tab-content text-muted">
                      <div class="tab-pane active show" id="nav-pianifica-lavori" role="tabpanel">
                        <div class="">
                          <template v-if="data.project &&  data.worksite">
                            <pianificazioneLavori :projectID="data.project._id" :worksiteID="data.worksite._id" />
                           </template>
                        </div>
                      </div>
                      <div class="tab-pane" id="nav-componenti" role="tabpanel">
                        <div class="">
                        <template v-if="data.project &&  data.worksite">
                          <template v-if="tabProdotti">
                            <elencoProdottiCantiere type="worksite" :projectID="data.project._id" :worksiteID="data.worksite._id" taskID="" interventionID="" :data="data"  @updateTeam="getItem"   />
                          </template>
                        </template>
                        </div>
                      </div>
                      <div class="tab-pane" id="nav-timeline" role="tabpanel">
                        <div class="">
                        <template v-if="data.project &&  data.worksite">
                          <template v-if="tabTimeline">
                            <h3>Timeline</h3>
                          </template>
                        </template>
                        </div>
                      </div>
                      <div class="tab-pane" id="nav-team" role="tabpanel">
                        <div class="">
                          <template v-if="data.project &&  data.worksite">
                          <template v-if="tabTeam">
                            <gestioneTeamDiLavoro  type="worksite" taskID="" interventionID="" :projectID="data.project._id" :worksiteID="data.worksite._id" :data="data"  @updateTeam="updateTeamParent" />
                          </template>
                        </template>
                        </div>
                      </div>
                      <div class="tab-pane" id="nav-mezzi" role="tabpanel">
                        <div class="mt-2">
                          <template v-if="data.project &&  data.worksite">
                          <template v-if="tabMezzi">
                            <gestioneMezziCantiere  type="worksite" :projectID="data.project._id" :worksiteID="data.worksite._id" taskID="" interventionID="" :data="data"  @updateTeam="updateTeamParent" />
                          </template>
                        </template>
                        </div>
                      </div>
                      <div class="tab-pane" id="nav-attrezzature" role="tabpanel">
                        <div class="mt-2">
                          <template v-if="data.project &&  data.worksite">
                          <template v-if="tabAttrezzature">
                            <gestioneAttrezzaturaCantiere type="worksite" :projectID="data.project._id" :worksiteID="data.worksite._id" taskID="" interventionID="" :data="data"  @updateTeam="updateTeamParent" />
                          </template>
                        </template>
                        </div>
                      </div>
                    </div>
                  </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>   
</template>